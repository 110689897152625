import axios from "axios";
import { RewriteResponseData } from "../components/ResultSection";

// Interface for the overall API response
interface ApiResponse {
  success: boolean;
  data: RewriteResponseData;
}

export default async function getRewrite(
  content: string,
  password: string
): Promise<RewriteResponseData> {
  const resp = await axios.post(
    `${process.env.REACT_APP_API_URL}/rewrite/start`,
    {
      password,
      content,
    }
  );

  if (!resp.data) {
    throw new Error("Some error occurred");
  }

  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data.data;
}
