import React from "react";
import Sidebar from "../components/Sidebar";
import MainSection from "../components/MainSection";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AppLayout: React.FC = () => {
  return (
    <div className="flex min-h-screen bg-[#1E1F1D]">
      <ToastContainer />
      {/* <Sidebar /> */}
      <MainSection />
    </div>
  );
};

export default AppLayout;
