import React from 'react';
import { ConfirmSubmissionTick } from '../../constants/Svg';

interface ConfirmSubmissionModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}

export default function ConfirmSubmissionModal({
  isOpen,
  setOpen,
  onConfirm,
}: ConfirmSubmissionModalProps) {
  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-[#353537] border rounded-lg px-[2.625rem] py-[3.875rem] w-[426px] border-[#4A4D43] flex flex-col items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="rounded-full p-2 mb-4">
            <ConfirmSubmissionTick />
          </div>
          <h2 className="text-[#f4f4f4] text-[26px] font-semibold mb-1">
            Confirm Submission
          </h2>
          <p className="text-[#CDCECD] text-center mb-9 text-sm">
            You are using the recreate function, confirm submission
          </p>
        </div>
        <button
          onClick={handleConfirm}
          className="w-[14.375rem] bg-gradient-to-r from-[#9EFFBD] to-[#C7FF6B] text-black font-semibold text-wer py-3 rounded-md mb-3 hover:bg-green-600 transition-all hover:scale-105 "
        >
          Confirm Result
        </button>
        <button
          onClick={handleCancel}
          className="w-[14.375rem] bg-[#1E1F1D] text-[#7E847E] py-3 rounded-md font-semibold transition-all hover:scale-105"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
