import React, { KeyboardEvent, useState } from "react";
import { ConfirmSubmissionTick } from "../../constants/Svg";
import { toast } from "react-toastify";

interface PasswordModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (password: string) => void;
}

export default function PasswordModal({
  isOpen,
  setOpen,
  onSubmit,
}: PasswordModalProps) {
  const [password, setPassword] = useState("");

  if (!isOpen) return null;

  const handleSubmit = () => {
    if (!password) return toast.error("Enter a valid password");
    onSubmit(password);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-[#353537] border rounded-lg px-[2.625rem] py-[3.875rem] w-[426px] border-[#4A4D43] flex flex-col items-center justify-center">
        <div className="flex flex-col items-center">
          <h2 className="text-[#f4f4f4] text-2xl font-semibold mb-2">
            Enter Password
          </h2>
          <p className="text-[#CDCECD] text-center mb-9 text-sm">
            Please enter your password to continue
          </p>
        </div>
        <input
          type="password"
          value={password}
          onKeyDown={handleKeyPress}
          onChange={(e) => setPassword(e.target.value)}
          className="w-[14.375rem] bg-[#1E1F1D] text-[#CDCECD] py-3 px-4 rounded-md mb-3 focus:outline-none focus:ring-2 focus:ring-[#9EFFBD]"
          placeholder="Enter your password"
        />
        <button
          onClick={handleSubmit}
          className="w-[14.375rem] hover:scale-105 bg-gradient-to-r from-[#9EFFBD] to-[#C7FF6B] text-black font-semibold text-wer py-3 rounded-md mb-3 hover:opacity-90 transition-all"
        >
          Submit
        </button>
      </div>
    </div>
  );
}
