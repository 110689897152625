import React from 'react';

export default function Loader() {
  return (
    <div className="flex justify-center items-center h-full flex-col gap-3">
      <span className="inline-flex w-max h-max animate-spin-slow">
        <img
          src={require('../assets/loading.png')}
          alt="loading"
          className="w-[72px] h-[72px]"
        />
      </span>
      <div className="font-medium text-[#C7FF6B]">Generating...</div>
    </div>
  );
}
