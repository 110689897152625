import React, { useState } from "react";
import {
  CopyIcon,
  GearIcon,
  InfoSvg,
  PageIcon,
  SparkIcon,
} from "../constants/Svg";
import Loader from "./Loader";
import { useRecoilValue } from "recoil";
import { appStatusAtom } from "../recoil/appState";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";

// Interface for the nested data object
export interface RewriteResponseData {
  result?: string;
  howLikelyToBeDetected?: number;
  systemTokensSpent?: number;
  tokensSpent?: number;
  totalTokensSpent?: number;
  remainingCredits?: number;
}

interface ResultSectionProp extends RewriteResponseData {
  handleRetry?: () => void;
}

function ResultSection({
  result,
  totalTokensSpent,
  remainingCredits,
  howLikelyToBeDetected,
  tokensSpent,
  systemTokensSpent,
  handleRetry,
}: ResultSectionProp) {
  const status = useRecoilValue(appStatusAtom);
  const [text, setText] = useState("");

  const handleCopy = () => {
    navigator.clipboard.writeText(result || "");
    toast.success("Copied to clipboard");
  };

  return (
    <div className="bg-[#4A4D43] mt-4 p-4 rounded-lg shadow-lg min-h-[720px] h-full flex flex-col">
      <div className="flex items-center justify-between">
        <h2 className="font-semibold flex items-center gap-2">
          <PageIcon />
          Result
        </h2>
      </div>

      <div className="flex flex-col h-full border border-[#505349] rounded-lg p-5 mt-4">
        <div className="bg-[#2C2C2E] rounded-md h-full flex flex-col p-5">
          {status === "loading" ? (
            <Loader />
          ) : (
            <>
              <textarea
                className="w-full resize-none bg-transparent text-[#F4F4F4] placeholder-[#8B8B8B] border-none outline-none text-[14px] leading-loose h-full pr-4"
                placeholder="The Original Text supports English, Chinese, and Japanese..."
                value={result || ""}
                disabled
              />
              <div className="flex items-end justify-between h-[36px]">
                <div
                  onClick={handleCopy}
                  className="flex items-center justify-center cursor-pointer"
                >
                  <CopyIcon />
                </div>
                {/* <div className="text-[15px] text-[#8B8B8B]">
                  {text.length}/2000
                </div> */}
              </div>
            </>
          )}
        </div>
        <div className="flex justify-between items-center mt-4 ">
          <p className="text-[14px]">
            Total credits spent:{" "}
            <span className="text-[#C7FF6B]">{totalTokensSpent || 0}</span>,
            Remaining Credits:{" "}
            <span className="text-[#C7FF6B]">{remainingCredits || 0}</span>
          </p>
          <motion.button
            className="bg-[linear-gradient(to_right,#9EFFBD,#C7FF6B)] px-6 py-3 font-semibold text-black text-[18px] leading-[28px] rounded-[6px] flex gap-[6px] items-center transition-all hover:scale-105"
            onClick={() => {
              handleRetry?.();
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <SparkIcon />
            New
          </motion.button>
        </div>
      </div>
    </div>
  );
}

export default ResultSection;
