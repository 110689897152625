import axios from "axios";

export default async function checkPassword(
  password: string
): Promise<boolean> {
  const resp = await axios.post(
    `${process.env.REACT_APP_API_URL}/check-password`,
    {
      password,
    }
  );

  if (!resp.data) {
    throw new Error("Some error occurred");
  }

  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return !!resp.data.correct;
}
