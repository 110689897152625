import React from 'react';
import AppLayout from './layout/AppLayout';
import './App.css';
import { RecoilRoot } from 'recoil';

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <AppLayout />
    </RecoilRoot>
  );
};

export default App;
