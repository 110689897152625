import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ConfirmSubmissionModal from "./modals/ConfirmSubmissionModal";
import PasswordModal from "./modals/PasswordModal";
import { CopyIcon, GearIcon, InfoSvg, SparkIcon } from "../constants/Svg";
import { useRecoilState } from "recoil";
import ResultSection, { RewriteResponseData } from "./ResultSection";
import { appStatusAtom } from "../recoil/appState";
import checkPassword from "../function/checkPassword";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import getRewrite from "../function/getRewrite";

const MainSection: React.FC = () => {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [status, setStatus] = useRecoilState(appStatusAtom);
  const [text, setText] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [rewriteResp, setRewriteResp] = useState<RewriteResponseData | null>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isAuthorized = !!localStorage?.getItem("password");
    if (!isAuthorized && isFirstLoad) {
      setPasswordModalOpen(true);
      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  const handlePasswordSubmit = async (password: string) => {
    try {
      if (loading) return;
      setLoading(true);
      const isValid = await checkPassword(password);
      if (!isValid) {
        return toast.error("Invalid password");
      }

      localStorage.setItem("password", password);
      setPasswordModalOpen(false);
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        if (e instanceof AxiosError) {
          return toast.error(e?.response?.data?.error || "Some error occurred");
        } else {
          return toast.error(e.message);
        }
      }
      return toast.error("Some error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSubmit = async () => {
    if (status === "loading") return;
    try {
      if (!text) throw new Error("Please write some content");
      const password = localStorage?.getItem("password");
      if (!password) throw new Error("Unauthorized");
      setConfirmModalOpen(false);
      setStatus("loading");
      const resp = await getRewrite(text, password);
      setRewriteResp(resp);
      setStatus("success");
    } catch (e) {
      console.log(e);
      setStatus("idle");
      if (e instanceof Error) {
        if (e instanceof AxiosError) {
          return toast.error(e?.response?.data?.error || "Some error occurred");
        } else {
          return toast.error(e.message);
        }
      }
      return toast.error("Some error occurred");
    }
  };

  const handleSubmit = () => {
    if (status === "loading") return;
    setConfirmModalOpen(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  const countWords = (str: string): number => {
    // Remove leading and trailing whitespace and split by one or more whitespace characters
    return str
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  };
  const wordCount = countWords(text);

  return (
    <motion.div
      className="flex flex-col flex-1 max-h-screen min-h-0 overflow-auto bg-[linear-gradient(310.96deg,rgba(230,243,115,0.6)_-29.42%,rgba(158,255,189,0.2)_17.17%,rgba(28,28,30,0)_54.42%)] text-white p-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <ConfirmSubmissionModal
        isOpen={isConfirmModalOpen}
        setOpen={setConfirmModalOpen}
        onConfirm={handleConfirmSubmit}
      />
      <PasswordModal
        isOpen={isPasswordModalOpen}
        setOpen={setPasswordModalOpen}
        onSubmit={handlePasswordSubmit}
      />
      <motion.div
        className="bg-[#4A4D43] p-4 rounded-lg shadow-lg min-h-[720px] flex flex-col"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <motion.div
          className="flex items-center justify-between"
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <h2 className="font-semibold flex items-center gap-2">
            <GearIcon />
            Writing Setting
          </h2>
          <div className="flex items-center gap-2">
            <p className="text-sm font-medium">Service :</p>
            <div className="items-center bg-[#2C2C2E] p-3 flex gap-2 rounded-lg font-medium text-[#C7FF6B]">
              <InfoSvg />
              ZeroBypass
            </div>
          </div>
        </motion.div>

        <motion.div
          className="flex flex-col h-full border border-[#505349] rounded-lg p-5 mt-4"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <p className="text-[#AFB4AF] font-semibold mb-3">Original Text</p>
          <div className="bg-[#2C2C2E] rounded-md h-full flex flex-col p-5">
            <textarea
              className="w-full resize-none bg-transparent text-[#F4F4F4] placeholder-[#8B8B8B] border-none outline-none text-[14px] leading-loose h-full pr-4"
              placeholder="The Original Text supports English, Chinese, and Japanese..."
              value={text}
              onChange={(e) => setText(e.target.value)}
              disabled={status === "success"}
            />
            <div className="flex items-end justify-between h-[36px]">
              <div
                onClick={handleCopy}
                className="flex items-center justify-center cursor-pointer"
              >
                <CopyIcon />
              </div>
              <div className="text-[15px] text-[#8B8B8B]">
                {text.length}/2000
              </div>
            </div>
          </div>
          {status === "idle" && (
            <motion.div
              className="flex justify-between items-center mt-4"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.8, duration: 0.5 }}
            >
              <p className="text-[14px]">
                Consume <span className="text-[#C7FF6B]">1</span> Credit per
                word, expected consumption{" "}
                <span className="text-[#C7FF6B]">{wordCount}</span> Credit
                {wordCount !== 1 && "s"}
              </p>
              <motion.button
                className="bg-[linear-gradient(to_right,#9EFFBD,#C7FF6B)] px-6 py-3 font-semibold text-black text-[18px] leading-[28px] rounded-[6px] flex gap-[6px] items-center transition-all hover:scale-105"
                onClick={handleSubmit}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <SparkIcon />
                Submit
              </motion.button>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
      <AnimatePresence>
        {status !== "idle" && (
          <motion.div
            className="flex flex-col flex-1 h-full"
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            // exit={{ y: "100%" }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
              duration: 1,
            }}
          >
            <ResultSection
              {...rewriteResp}
              handleRetry={() => {
                setStatus("idle");
                setText("");
                setRewriteResp(null);
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default MainSection;
